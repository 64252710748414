import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import Button, { ButtonType } from '../../../shared/form-control/Button';
import DropdownSelect from '../../../shared/form-control/DropdownSelect';
import DeleteIcon from '../../../shared/icon/DeleteIcon';
import PlusIcon from '../../../shared/icon/PlusIcon';
import ActionEditorBase from '../ActionEditorBase';

const ViewEditor: FC<EditorProps> = (props) => {
  const { action, patchAction, patchData, form, invalidStates } = props;
  const [viewActions, setActions] = useState<string[]>(action.data?.viewActions || []);

  const { t } = useTranslation('form-builder');

  const actionOptions = useMemo(() => {
    const result = [];
    for (let sectionIdx = 0; sectionIdx < form.sections.length; sectionIdx++) {
      const section = form.sections[sectionIdx];

      for (let actionIdx = 0; actionIdx < section.actions.length; actionIdx++) {
        const action = section.actions[actionIdx];
        const actionText = `${sectionIdx + 1}.${actionIdx + 1} ${t(`action-types.${action.type}`)} ${
          invalidStates && invalidStates[action.id]?.length ? '⚠' : ''
        }`;
        result.push({ id: action.id, text: actionText || 'Unknown action', value: action.id });
      }
    }

    return result;
  }, [form.sections, invalidStates, t]);

  useEffect(() => {
    if (action.noninteractive) {
      return;
    }

    patchAction({
      noninteractive: true,
    });
  }, [action.noninteractive, patchAction]);

  const addAction = () => {
    setActions((prev) => {
      const data = [...prev, actionOptions[0].id];
      patchData({ viewActions: data });
      return data;
    });
  };

  const removeAction = (index: number) => {
    setActions((prev) => {
      const data = prev.filter((_, i) => i !== index);
      patchData({ viewActions: data });
      return data;
    });
  };

  const updateAction = (index: number, actionId: string) => {
    setActions((prev) => {
      const newArr = [...prev];
      newArr[index] = actionId;
      patchData({ viewActions: newArr });
      return newArr;
    });
  };

  const selectedAction = useCallback(
    (id: string) => {
      return actionOptions.find((x) => x.id === id);
    },
    [actionOptions],
  );

  return (
    <div data-cy="resource-picklist-editor">
      <ActionEditorBase {...props}>
        <div className="text-dpm-12 mt-4">{t('view.title')}</div>
        <div className="bg-gray-5 flex w-full items-start justify-between rounded-[10px] p-4">
          <div className="flex w-full flex-col gap-1">
            {viewActions.map((a, i) => (
              <div key={`${i + a}`} className="flex w-full items-start justify-between">
                <DropdownSelect
                  data-cy="action"
                  className="w-full"
                  options={actionOptions}
                  placeholder=""
                  onChange={(option) => {
                    updateAction(i, option.id);
                  }}
                  value={selectedAction(a)}
                />
                <DeleteIcon onClick={() => removeAction(i)} className="ml-4 mt-3 h-7 w-7" />
              </div>
            ))}
            <div>
              <Button type={ButtonType.TERTIARY} onClick={addAction} className="px-0">
                <Button.Slot name="Icon">
                  <PlusIcon className="h-3 w-3" />
                </Button.Slot>
                {t('view.add-new-item')}
              </Button>
            </div>
          </div>
        </div>
      </ActionEditorBase>

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} />
    </div>
  );
};

export default ViewEditor;
